@font-face {
  font-family: "Inter";
  src: url("../public/fonts/Inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("../public/fonts/Inter/Inter-Bold.ttf");
}

* {
  box-sizing: border-box;
  font-family: "Inter" !important;
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter";
}
