@import "App.scss";

.container {
  width: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  background: #f3f6f585;
  border: 1px solid;
  border-image-source: linear-gradient(
    90.66deg,
    rgba(255, 255, 255, 0) -0.38%,
    rgba(255, 255, 255, 0.6) 12.68%,
    #ffffff 48.03%,
    rgba(255, 255, 255, 0.6) 79.36%,
    rgba(255, 255, 255, 0) 98.24%
  );
  border-radius: 12px;
  padding: 35px 27px 35px 35px;
}

.head {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.title {
  color: #101828;
  @include BigTitleBoldText;
}

.text {
  color: #040637;
  @include RegularTitleText;
}
