@import "App.scss";

.dialogPaper {
  background-color: white;
  padding: 20px;

  &.dark {
    background-color: $bg-color-dark-2;
    box-shadow: 0px 4px 17.5px 0px #00000040;
  }

  &.radius-20 {
    border-radius: 20px;
  }

  &.xs {
    max-width: 290px;
  }
  &.sm {
    max-width: 570px;
    padding: 24px;
    border-radius: 16px;
  }
  &.md-wide {
    width: calc(100vw - 40px);
    height: 572px;
  }
  &.md {
    max-width: 1320px;
  }
  &.lg {
    max-width: 1280px;
  }
  &.xl {
    max-width: 1920px;
  }

  @media (max-width: 600px) {
    max-width: calc(100% - 20px);
    margin: 10px;
  }
}

.backdrop {
  background-color: transparent;
}
