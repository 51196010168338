@import "App.scss";

.container {
  max-width: 362px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.text {
  @include RegularTitleThinText;
  color: #404040;
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #000;
}
