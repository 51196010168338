.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100dvw;
  background: transparent;
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalContent {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 8px;
}

.closeButton {
  position: absolute;
  top: 44px;
  right: 44px;
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  background: #ffffff;
  border: none;
  cursor: pointer;
}
