.brandCreationDialog {
  padding: 0 !important;
}

.contentContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 722px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .leftBlock {
      display: flex;
      align-items: center;
      gap: 16px;
      .title {
        font-size: 40px;
        font-weight: 400;
        color: #c5c5c5;
      }
      .arrowIconBlock {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 8px;
        &:hover {
          background-color: #404040;
          cursor: pointer;
        }
      }
    }
    .closeIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 44px;
      height: 44px;
      border-radius: 8px;
      cursor: pointer;
      &.active,
      &:hover {
        background-color: #404040;
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .footer {
    display: flex;
    gap: 16px;
    .saveBtn {
      flex: 1;
    }
  }
}

.tooltipContent {
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;

  .title,
  .contentTxt {
    text-align: center;
  }
}

.savedClientsList {
  padding: 2px;
  max-height: 601px;
  overflow-y: auto;
  display: flex;
  gap: 24px 16px;
  flex-wrap: wrap;
}
