@import "App.scss";

.container {
  position: fixed;
  width: 100%;
  height: 8px;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.83);
  z-index: $z-index-3;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.isOpen {
    height: 100vh;
    top: 0;
    .content {
      min-height: 234px;
      max-height: fit-content;
      width: 100%;
      width: fit-content;
      background-color: #fff;
      display: flex;
      transition: all 0.8s ease;
    }
    .configuratorContent {
      height: 300px;
      background-color: #fff;
      transform: translateY(0);
      transition: all 0.8s ease;

      .innerContent {
        width: 100%;
        height: 100%;
      }

      &.isExpanded {
        transform: translateY(50px);
        height: 100vh;
        padding-bottom: 160px;
        .innerContent {
          overflow: auto;
        }
      }
    }
  }

  &.isExpanded {
    height: auto;
    top: 0;

    .content {
      max-height: auto;
      width: 100%;
      width: fit-content;
      background-color: #fff;
      display: flex;
      transition: all 0.5s ease;
    }
    .configuratorContent {
      background-color: #fff;
      transform: translateY(40px);
    }
  }

  .content {
    min-height: 300px;
  }

  .configuratorContent {
    padding-left: 120px;
    background-color: #fff;
    transform: translateY(345px);
    transition: all 0.5s ease;
  }

  .lineItems {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 16px;
    overflow: auto;
    max-width: 100%;
    padding-top: 40px;
    &.hasOverflow {
      margin-right: 24px;
      box-shadow: inset -4px 0px 14px 0px #00000017;
    }

    &::-webkit-scrollbar {
      position: absolute;
      bottom: 40px;
    }
  }
}

.detailsBlock {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-right: 120px;
  margin-top: 60px;

  .contentSection {
    display: flex;
    column-gap: 100px;

    .shortDesc {
      font-size: 20px;
      font-weight: 400;
      color: $color-text-gray;
      margin-bottom: 30px;
    }
    .rightSec {
      width: 474px;
      min-width: 443px;
      height: 259px;
      border-radius: 16px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .leftSec {
      .optionsBlock {
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        // grid-gap: 10px 100px;
        display: flex;
        column-gap: 60px;
        row-gap: 20px;
        flex-wrap: wrap;
      }
    }
  }
  .description {
    color: $color-text-gray;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
  }
}

.cellsBlock {
  height: 340px;
  margin-top: 60px;

  .content {
    display: flex;
    column-gap: 24px;
  }
  .cellsPart {
    display: flex;
    border-radius: 17px;
    border: 2px dashed #d2d2d2;
    background-color: #ecf9ff;
    height: fit-content;
    overflow: hidden;
    flex-shrink: 0;

    .cellContainer {
      padding: 14px 20px;
      cursor: pointer;

      &:hover,
      &.active {
        background-color: $color-config-hover;
      }
    }
  }
  .descPart {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-top: 16px;
    .title {
      color: $color-text-regular-main;
      font-size: 20px;
      font-weight: 700;
    }
    .desc {
      color: $color-text-regular-main;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.sectionTitle {
  font-size: 40px;
  font-weight: 700;
  color: $color-text-regular-main;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.footer {
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  padding-right: 120px;
  column-gap: 32px;
  justify-content: flex-end;
  box-shadow: 0px -4px 14px 0px #00000024;
  position: absolute;
  bottom: 50px;
  left: 0;
  background: #fff;
}

.standardFeaturesSec {
  margin-bottom: 60px;
  padding-right: 120px;
  display: flex;
  flex-direction: column;
  gap: 14px;

  .featureCircles {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    &.empty {
      width: fit-content;
      flex-wrap: nowrap;
      overflow: hidden;
    }
    &.disabledCircles {
      flex-wrap: nowrap;
      overflow: hidden;
      > div {
        flex-shrink: 0;
      }
    }
  }
  .descriptionContent {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .contentTitle {
      @include BigTitleBoldText;
      font-weight: 700;
      color: $color-text-regular-main;
    }
    .contentDesc {
      @include BigTitleText;
      color: $color-text-regular-main-opc-50;
    }
  }
}

.optionsSec {
  padding-right: 120px;
  margin-bottom: 60px;
  .options {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    column-gap: 24px;
    max-height: 600px;
    flex-wrap: wrap;
  }
}

.otherOptionsSec {
  padding-right: 120px;
  .otherOptions {
    display: flex;
    column-gap: 100px;
    row-gap: 16px;
    flex-wrap: wrap;
  }
}

.leftSideSteps {
  position: absolute;
  left: 50px;
  bottom: 200px;
}

.spacedBetweenRow {
  display: flex;
  justify-content: space-between;
}
