@import "App.scss";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: #000;
  padding: 42px;
}

.cellBlock {
  cursor: pointer;
  display: flex;
  column-gap: 12px;
  width: fit-content;
  width: 224px;

  .nameBlock {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  .name {
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    margin-top: 30px;
  }

  .width {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
  }

  &:hover {
    .name {
      color: $color-config-hover;
    }
    .cell {
      outline: 3px solid $color-config-hover;
    }
  }
}

.title {
  font-size: 32px;
  font-weight: 700;
  color: #2e2e2e;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
}

.productCellsBlock {
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  column-gap: 28px;
}
