@import "App.scss";

.container {
  background-color: $dark-bg-color;
  height: 580px;
  display: flex;
  column-gap: 50px;
  padding: 20px 96px 240px 20px;
  z-index: $z-index-5;
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.titleBlock {
  padding-bottom: 16px;
  border-bottom: 1px solid #454545;
}

.contentBlock {
  display: flex;
  column-gap: 24px;
}

.title {
  @include RegularTitleText;
  text-transform: capitalize;
}

.subTitle {
  @include RegularTitleThinText;
  color: $color-text-gray;
  text-transform: capitalize;
  text-decoration: none;

  &.highlighted {
    pointer-events: none;
    color: $color-text-gray-active;
  }
  &:hover {
    color: $color-primary-active;
  }
}
