@import "App.scss";

.characteristicsVerticalSwiper {
  width: 100%;
  height: 640px;
  position: relative;

  .content {
    max-width: calc(100vw - 580px);
    margin-right: 40px;
    .contentImage,
    .contentVideo {
      width: 100%;
      height: auto;
    }
  }

  :global(.swiper-pagination) {
    position: absolute;
    left: 10px !important;
    top: 140px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5px;
    row-gap: 5px;
  }

  :global(.swiper-pagination-bullet) {
    position: relative;
    background-color: #213d4a;
    width: 2px;
    height: 25px;
    margin: 0 !important;
    border-radius: 10px;
  }

  :global(.swiper-pagination-bullet-active) {
    width: 4.2px;
    height: 25px;
    background-color: #292929;

    &::after {
      content: attr(data-label);
      position: absolute;
      top: 4px;
      margin-left: 7px;
      color: #292929;
      @include SmallText;
      width: 160px;
      text-align: start;
    }
  }
}

.swiperItem {
  width: 100%;
  display: flex;
}
