@import "App.scss";

.container {
  width: 100%;
  height: 140px;
  background-color: $color-form-elm-bg-dark;
  padding: 16px;
  border-radius: 16px;
  display: flex;
  column-gap: 16px;
  align-items: center;

  .imageContainer {
    width: 108px;
    height: 108px;
    border: $border-form-elm-dark;
    border-radius: 8px;
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  .descBlock {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .spaceName {
      @include RegularTitleMidText;
      color: #fff;
    }
    .row {
      @include SmallText;
      color: $color-text-grey-3;
      display: flex;
      column-gap: 8px;
    }
  }
}
