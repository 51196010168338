@import "App.scss";

.container {
  display: flex;
  flex-direction: column;

  .name {
    @include SmallText;
    color: $color-text-regular-main;
  }

  .measurementUnit {
    white-space: nowrap;
    font-size: 20px;
    font-weight: 700;
    color: $color-text-regular-main;
    max-width: 200px;
  }
}
