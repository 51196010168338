@import "App.scss";

.container {
  background-color: $bg-color-dark-2;
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 20px;
}

.leftSec {
  padding: 40px 20px 40px 40px;
  display: flex;
  flex: 1;
  min-width: 640px;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    column-gap: 50px;
    align-items: center;

    .logo {
      width: 200px;
      cursor: pointer;
    }

    .verticalLine {
      width: 2px;
      height: 70px;
      border: 1px solid #cdcdcd;
    }
    .companyName {
      font-size: 38px;
      font-weight: 600;
      color: #fff;
      width: 200px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    max-width: 480px;

    .title {
      font-size: 64px;
      font-weight: 600;
      color: #fff;

      .name {
        color: #a30a2a;
      }
    }
    .description {
      @include RegularTitleText;
      color: #cdcdcd;
    }
  }

  .letStartBlock {
    height: 95px;
    border-radius: 16px;
    padding: 17px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 16px;
    box-shadow: 0px 4px 17.5px 0px #00000040;
    background-color: $color-text-dark-1;

    .letsStartTxt {
      max-width: 300px;
      color: #cdcdcd;
    }
    .startBtn {
      background-color: #a30a2a;
      border-radius: 10px;
      padding: 18px 40px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      border: none;
      text-decoration: none;
    }
  }
}

.homeSlider {
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );

  :global(.swiper-pagination) {
    margin-right: 40px;
  }

  :global(.swiper-pagination-bullets) {
    top: unset;
    bottom: 0;
  }

  :global(.swiper-pagination-bullet) {
    background-color: #fff;
    width: 19px;
    height: 19px;
    transform: unset;
    margin-bottom: 24px !important;
    opacity: 1;
  }

  :global(.swiper-pagination-bullet-active) {
    background-color: #8b1516;
  }
}

.companyLogoContainer {
  border-radius: 50%;
  overflow: hidden;
  height: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
