@import "App.scss";

.container {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
}

.featuresBlock {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  background-color: #eff2f1;
}

.features {
  position: relative;
  width: 100%;
  height: 720px;
  background-image: url("../../assets/backgrounds/standard-features.png");
  background-repeat: no-repeat;
  background-size: contain;
  max-width: 1440px;

  @include SmallNotebook {
    height: 500px;
    width: 1000px;
    & > div:nth-child(2) {
      top: 22px !important;
      left: 100px !important;
    }
    & > div:nth-child(3) {
      top: 102px !important;
      left: 70px !important;
    }
    & > div:nth-child(4) {
      top: 182px !important;
      left: 100px !important;
    }
    & > div:nth-child(5) {
      top: 262px !important;
      left: 70px !important;
    }
    & > div:nth-child(6) {
      top: 342px !important;
      left: 160px !important;
    }
    & > div:nth-child(7) {
      top: 422px !important;
      left: 240px !important;
    }
    & > div:nth-child(8) {
      top: 342px !important;
      left: 640px !important;
    }
    & > div:nth-child(9) {
      top: 262px !important;
      left: 664px !important;
    }
    & > div:nth-child(10) {
      top: 182px !important;
      left: 686px !important;
    }
    & > div:nth-child(11) {
      top: 102px !important;
      left: 740px !important;
    }
    & > div:nth-child(12) {
      top: 22px !important;
      left: 650px !important;
    }
  }
}

.title {
  @include LargeBoldTitleText;
  text-align: center;
  width: 180px;
  margin: auto;
  position: absolute;
  left: calc(50% - 86px);
  top: calc(50% - 40px);

  .titleIcon {
    position: absolute;
    top: -24px;
    right: -16px;
  }
}

.infos {
  display: flex;
  flex-wrap: wrap;
  padding: 96px 32px 96px 58px;
  column-gap: 35px;
  row-gap: 57px;
  background-color: #fff;
}

.systemOptions {
  max-width: 1440px;
  height: 878px;
  width: 100%;
  position: relative;
  padding: 0 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  row-gap: 40px;
  background-color: $bg-color-sec-1;

  .prodImgContainer {
    width: 504px;
    height: 504px;
    border-radius: 50%;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: relative;
    z-index: 9;

    @include BigLaptop {
      width: 188px;
      height: 188px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-radius: 50%;
      top: -25px;
      left: -25px;
      right: -25px;
      bottom: -25px;
    }

    &::before {
      border: 26px solid #ebebeb; /* First border color and width */
    }

    &::after {
      border: 26px solid #f3f3f3; /* Second border color and width */
      top: -50px;
      left: -50px;
      right: -50px;
      bottom: -50px;
    }

    .productImg {
      height: 100%;
      max-height: 440px;
      z-index: 99;
      cursor: pointer;
    }
  }
}

.productImgInModal {
  height: 90dvh;
}

.optionsBg {
  position: absolute;
  top: -140px;
  font-size: 360px;
  font-weight: 700;
}

.sectionTitleBlock {
  position: relative;
}

.sectionTitle {
  font-size: 48px;
  font-weight: 700;
  margin: 0;
}

.leftWavesIcon {
  position: absolute;
  left: -20px;
  top: -20px;
}

.sysOptsContent {
  display: flex;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sysOptsColumn {
  display: flex;
  flex-direction: column;
  column-gap: 32px;
  row-gap: 8px;
  padding: 24px 74px;
  background-color: #f3f6f585;
  min-width: 600px;
  max-width: 44%;

  .sysOptionRow {
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}

.colTitleBlock {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  column-gap: 16px;

  .colTitle {
    font-size: 20px;
    font-weight: 600;
  }
}

.section {
  position: relative;
  padding: 147px 124px 80px 121px;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  background-color: #fff;
}

.otherChoiceContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 40px;
}
