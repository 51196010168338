@import "App.scss";

.container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  background-image: url(./Location.png);
  background-repeat: no-repeat;
  background-size: cover;

  .closeIconContainer {
    position: absolute;
    top: 0;
    right: 16px;
    cursor: pointer;
  }

  .rightSection {
    width: 550px;
    min-height: 560px;
    color: #fff;
    display: flex;
    flex-direction: column;
    column-gap: 32px;
    padding: 60px 40px 0 40px;

    border-left: 1px solid;

    border-image: linear-gradient(
      0.89deg,
      rgba(255, 255, 255, 0) 1.53%,
      rgba(255, 255, 255, 0.6) 14.57%,
      #ffffff 49.86%,
      rgba(255, 255, 255, 0.6) 81.14%,
      rgba(255, 255, 255, 0) 100%
    );

    .titleContainer {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 63px;
        font-weight: 700;

        &.red {
          color: $color-primary-active;
        }
      }
    }
    .description {
      @include RegularTitleThinText;
    }
  }
  .leftSection {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    flex: 1;
    min-height: 560px;
    max-height: 572px;
    overflow-y: auto;
    padding: 20px;

    .primaryLocationsBlock {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      width: 100%;
    }
    .allLocationsBlock {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}

.verticalLine {
  height: 100%;
  width: 2px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
