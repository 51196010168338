@import "App.scss";

.container {
  width: 167px;
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #d2d2d2;
  border-radius: 60px;
  transition: all ease 0.8s;
  cursor: pointer;
  gap: 4px;
  box-sizing: border-box;

  .featureTxt {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .iconContainer {
    width: 50px;
    height: 50px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #d2d2d2;
    flex-shrink: 0;
  }

  &:hover:not(.active) {
    border-color: $color-config-hover;
    .iconContainer {
      border-color: $color-config-hover;

      svg {
        path {
          stroke: $color-config-hover;
        }
        circle {
          stroke: $color-config-hover;
          fill: white;
        }
      }
    }
  }

  &.active {
    border-color: $color-config-hover;

    .iconContainer {
      background-color: $color-config-hover;
    }

    svg {
      path {
        stroke: white;
      }
      circle {
        stroke: white;
        fill: white;
      }
    }
  }
}
