@import "App.scss";

.container {
  width: 370px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  row-gap: 16px;
  box-shadow: 0px 0px 18px 0px #0000001a;
  background-color: #fff;
  padding: 16px;
  text-decoration: none;
}

.imgBlock {
  width: 340px;
  height: 230px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.name {
  color: #000000;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.desc {
  margin: 0;
  color: #000000;
  @include RegularTitleThinText;
}
