@import "App.scss";

.container {
  width: fit-content;
  height: fit-content;
}

.popoverContent {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  box-shadow: 0px 0px 18px 0px #0000001a;
  margin: 0 44px;
  border-radius: 8px;
}

.popoverItem {
  @include BigTitleBoldText;
  color: #000000;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    color: $color-primary-active;
  }
}
