@import "App.scss";

.container {
  width: 380px;
  height: 440px;
  background-color: $color-form-elm-bg-dark;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .imageContainer {
    width: 100%;
    height: 108px;
    border: $border-form-elm-dark;
    border-radius: 8px;
    margin-bottom: 4px;
  }
  .spaceName {
    @include RegularTitleMidText;
    color: #fff;
  }
  .row {
    @include SmallText;
    color: $color-text-grey-3;
    display: flex;
    column-gap: 8px;
  }
}
