.swiper {
  height: unset !important;

  .iconBulletBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #e6e6e6;
  }

  :global(.swiper-pagination-bullet) {
    background-color: #f0b6b7;
    width: 19px;
    height: 19px;
    transform: unset;
    margin-left: 36px !important;
    opacity: 1 !important;
  }

  :global(.swiper-pagination) {
    bottom: 146px !important;
  }

  :global(.swiper-pagination-bullet-active) {
    position: relative;
    background-color: #8b1516;

    &:global(.slider-custom-icon) {
      background-color: #fff;

      &::after {
        content: "";
        position: absolute;
        top: -6px;
        left: -6px;
        width: 50px;
        height: 50px;
        background: transparent;
        border-radius: 50%;
        border: 1px solid #8b1516;
      }
    }

    .iconBulletBlock {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #e6e6e6;

      .iconBullet {
        path {
          color: #8b1516;
          stroke: #8b1516;
        }
      }
    }
  }

  :global(.swiper-button-prev),
  :global(.swiper-button-next) {
    width: 40px;
    height: 40px;

    &::after {
      display: none;
    }
  }

  :global(.swiper-button-prev) {
    background: url("../../../assets/icons/left-circle-arrow.png");
    background-size: contain;
    // left: calc(50% - 180px);
  }

  :global(.swiper-button-next) {
    background: url("../../../assets/icons/right-circle-arrow.png");
    background-size: contain;
  }

  :global(.swiper-pagination-bullets-dynamic) {
    bottom: 40px;
  }

  :global(.slider-custom-icon) {
    background-color: transparent;
  }

  :global(.slider-custom-icon.first-enhance) {
    position: relative;
    margin-left: 90px !important;
  }

  :global(.slider-custom-icon.last-tech) {
    position: relative;
  }
}

.swiperItem {
  background-image: url(../../../assets/backgrounds/product-slide.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 117px;
  padding: 60px 200px 256px 120px;
  height: auto;
}

.productItem {
  max-height: 528px;
  width: 663px;

  img {
    object-fit: cover;
  }
}

.divider {
  width: 1px;
  height: 80px;
  display: inline-block;
  background-color: #404040;
  position: absolute;
  top: 0;
  left: -37px;
}

.enhTitle {
  position: absolute;
  bottom: calc(100% - 80px);
  font-size: 14px;
  line-height: 1;
  left: 0;
  color: #404040;
}

.techTitle {
  position: absolute;
  bottom: calc(100% - 80px);
  font-size: 14px;
  line-height: 1;
  left: 0;
  color: #404040;
}