@import "App.scss";

.counterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.counterButton {
  width: 32px;
  height: 16px;
  border: 1px solid $color-config-hover;
  background-color: #f0f0f0;
  color: $color-config-hover;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  &:disabled {
    cursor: default;
    opacity: 0.6;
    color: #d2d2d2;
    border-color: #d2d2d2;
  }
}

.counterDisplay {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e6f0fa;
  color: $color-config-hover;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid $color-config-hover;
  &:disabled {
    color: #d2d2d2;
    border-color: #d2d2d2;
  }
}
