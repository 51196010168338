@import "App.scss";

.fileInput {
  display: flex;
  align-items: center;
  background-color: $color-form-elm-bg-dark;
  color: $color-outline-dark;
  border-radius: 8px;
  padding: 8px 16px;
  width: 100%;
  justify-content: space-between;
  position: relative;
  border: $border-form-elm-dark;
  &.md {
    height: 54px;
  }
}

.inputLabel {
  flex-grow: 1;
}

.addOrChangeButton {
  color: $color-config-hover;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.hiddenInput {
  display: none;
}

.imagePreview {
  max-height: 40px;
  margin-right: 8px;
}
