@import "App.scss";

.container {
  position: absolute;
  width: fit-content;
  min-width: 202px;
  height: 44px;
  border-radius: 30px;
  box-shadow: 0px 16px 30px 0px #0000001f;
  display: flex;
  padding-right: 12px;
  align-items: center;
  column-gap: 12px;
  background-color: #fff;
}

.text {
  @include RegularTitleMidText;
  color: #040637;
  margin: 0;
}
