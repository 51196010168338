@import "App.scss";

.container {
  position: relative;
  width: 186px;
  height: 182px;
  background-color: #f6f6f6;
  border: 2.4px dashed #d2d2d2;
  border-radius: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    border-color: $color-config-hover;
    background-color: #ecf9ff;
  }

  .cellsBlock {
    display: none;
    background: #ecf9ff80;
  }

  .order {
    position: absolute;
    left: calc(50% - 20px);
    bottom: -20px;
    width: 40px;
    height: 40px;
    border: 2px solid #d2d2d2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #2e2e2e;
    font-size: 24px;
    font-weight: 700;
  }

  &.isSelected {
    border-color: $color-config-hover;
    background-color: #ecf9ff;
    .order {
      border-color: $color-config-hover;
      background-color: $color-config-hover;
      color: #fff;
    }
  }

  &.hoveredOn {
    &:hover {
      border-color: $color-config-hover;
      background-color: #ecf9ff;
      .order {
        border-color: $color-config-hover;
      }
      .cellsBlock {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.icons {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  column-gap: 32px;
}

.iconItem {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  &:hover,
  &.active {
    background-color: $color-config-hover;
    svg path {
      fill: #fff;
    }
  }
}


.popoverContent {
  padding: 40px 35px;
  display: flex;
  flex-direction: column;
  .title {
    margin-bottom: 32px;
    font-size: 32px;
    font-weight: 700;
  }
}

.products {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .prodName {
    @include RegularTitleMidText;
    cursor: pointer;

    &.disabled {
      cursor: default;
      pointer-events: none;
    }

    &:hover {
      color: $color-config-hover;
    }
  }
}

.itemProductImgBlock {
  width: 140px;
  height: 133px;
  .itemProductImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.cellItem {
  height: 134px;
  width: 41px;
  height: 64px;
  background-color: #d2d2d2;
  border-radius: 6px;
  padding: 2px;

  &:hover {
    background-color: $color-config-hover;
  }

  .innerBlock {
    border: 0.18px solid #000000;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    color: #d2d2d2;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5px;
    padding-top: 12px;
    padding-bottom: 16px;

    .text {
      font-size: 5px;
      font-weight: 700;
      color: #d2d2d2;
    }
  }
}
