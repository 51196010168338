.container {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  padding: 67px 100px 67px 67px;
  background-color: #252525;
  color: #fff;
  background-image: url(./Location.png);
  background-size: cover;
  background-repeat: no-repeat;

  .closeIconContainer {
    position: absolute;
    top: 0;
    right: 16px;
    cursor: pointer;
  }
  .title {
    font-size: 62px;
    font-weight: 700;
  }
  .descriptionContent {
    display: flex;
    flex-direction: column;
    row-gap: 46px;
    .description {
      font-size: 16px;
      font-weight: 400;
    }
  }
}
