@import "App.scss";

.swipeBannerBlock {
  position: relative;

  .contentBlock {
    position: absolute;
    top: 0;
    left: 20px;
    width: calc(100% - 40px);
    height: 100%;
    padding: 86px 20px 60px 120px;
    width: fit-content;
    display: flex;
    column-gap: 130px;
    z-index: $z-index-1;
    margin-right: 140px;
  }
  .playPauseElm {
    position: absolute;
    bottom: 40px;
    right: 80px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: $z-index-1;
    box-shadow: -0.3px 0.3px 0.3px -0.61px #ffffff59 inset;
    border: 0.42px solid;
    border-image-source: linear-gradient(
      202.36deg,
      rgba(255, 255, 255, 0.2) 8.26%,
      rgba(255, 255, 255, 0) 85.43%
    );
    background: #00000040;
    cursor: pointer;
  }
}

.bannerSwiper {
  height: 580px;
  margin-right: 20px;
  margin-top: -20px;

  :global(.swiper-pagination) {
    margin-right: 60px;
  }

  :global(.swiper-pagination-bullet) {
    position: relative;
    background-color: #7c7c7c;
    width: 12px;
    height: 12px;
    transform: unset;
    margin-bottom: 24px !important;
    opacity: 1;
    border: 0.4px solid #ffffff;
  }

  :global(.swiper-pagination-bullet-active) {
    background-color: #fff;
    svg {
      circle {
        fill: #fff;
      }
      path {
        fill: #8b1516;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: -11px;
      left: -11px;
      width: 30px;
      height: 30px;
      background: transparent;
      border-radius: 50%;
      border: 1px solid #8b1516;
    }
  }

  :global(.slider-custom-icon) {
    svg {
      position: absolute;
      left: -7px;
      top: -8px;

      &.infoBullet {
        top: -8px;
        left: -7px;
      }
      &.locationBullet {
        top: -8px;
        left: -8px;
      }
    }
  }
}

.container {
  position: relative;
  display: flex;
  column-gap: 130px;
  margin: 0 20px;
  height: 580px;
  width: calc(100vw - 40px);
  border-radius: 0 0 20px 20px;
  overflow: hidden;

  .bannerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .overlayBlock {
    position: absolute;
    top: 0;
    left: 0;
    background: #363636b8;
    width: 100%;
    height: 100%;
  }
}

.detailParametersBlock {
  display: flex;
  flex-direction: column;
  row-gap: 45px;
}

.param {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  color: #fff;
}

.paramValue {
  font-weight: 500;
  font-size: 53px;
}

.paramDesc {
  font-size: 20px;
  font-weight: 400;
}

.titleBlock {
  position: relative;
}

.patternIcon {
  position: absolute;
  left: 50px;
  top: -4px;
}

.bannerTitle {
  font-size: 62px;
  font-weight: 700;
  color: #fff;
  max-width: 680px;
  margin: 0;
  text-transform: capitalize;
}

.sectorText {
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  max-width: 680px;
  margin: 0;
  text-transform: capitalize;
}
