@import "App.scss";

.container {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  color: $color-text-dark-1;
  max-width: 356px;

  .section {
    @include BigTitleBoldText;
  }
  .title {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  .content {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    font-size: 20px;
    font-weight: 400;
    color: $color-text-gray-1;
  }
}
