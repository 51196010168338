.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.noPointerEvents {
  pointer-events: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom_scrollbar_block {
  position: relative;
  &::-webkit-scrollbar {
    position: absolute;
    height: 4px;
    margin-top: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #ebf3f6;
    height: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background: #008ecc;
    border-radius: 20px;
    height: 4px;
  }

  &.thin-scrollbar {
    &::-webkit-scrollbar {
      height: 2px;
      margin-left: 16px;
    }

    &::-webkit-scrollbar-track {
      height: 2px;
    }

    &::-webkit-scrollbar-thumb {
      height: 2px;
    }
  }
}

// variables

$color-red: #a30a2a;
$color-red-dark: #8b1516;
$color-primary-active: #dc2324;
$dark-bg-color: #282a2ef2;
$bg-color-dark-2: #252525;
$bg-color-sec-1: #f9f9f9;
$color-text-dark-1: #292929;
$color-text-regular-main: #2e2e2e;
$color-text-regular-main-opc-50: #2e2e2e83;
$color-text-gray: #8e8e8e;
$color-text-gray-1: #c4c4c4;
$color-text-gray-2: #eaeaea;
$color-text-grey-3: #7d7d7d;
$color-text-gray-active: #d9d9d9;
$color-config-hover: #008ecc;
$color-outline-dark: #646464;
$color-form-elm-bg-dark: #171717;

// z indexes

$z-index-1: 9;
$z-index-2: 99;
$z-index-3: 999;
$z-index-4: 9999;
$z-index-5: 99999;

// borders

$border-form-elm-dark: 1px solid #404040;

// mixins

@mixin SmallText {
  font-size: 14px;
  font-weight: 400;
}

@mixin RegularTitleText {
  font-size: 16px;
  font-weight: 600;
}

@mixin RegularTitleMidText {
  font-size: 16px;
  font-weight: 600;
}

@mixin RegularTitleThinText {
  font-size: 16px;
  font-weight: 400;
}

@mixin BigTitleText {
  font-size: 20px;
  font-weight: 400;
}

@mixin BigTitleBoldText {
  font-size: 20px;
  font-weight: 600;
}

@mixin LargeTitleText {
  font-size: 36px;
  font-weight: 500;
}

@mixin LargeBoldTitleText {
  font-size: 36px;
  font-weight: 700;
}

// Screen sizes

$bigLaptop: 1344px;
$smallNotebook: 1280px;
$macbookPro: 1440px;
$midSize: 1600px;
$big: 1920px;
$large: 2560px;
$ultraHD4K: 3840px;

// Mixins for each size
@mixin BigLaptop {
  @media (max-width: $bigLaptop) {
    @content;
  }
}

@mixin SmallNotebook {
  @media (max-width: $smallNotebook) {
    @content;
  }
}

@mixin MacbookPro {
  @media (max-width: $macbookPro) {
    @content;
  }
}

@mixin MidSize {
  @media (max-width: $midSize) {
    @content;
  }
}

@mixin Big {
  @media (max-width: $big) {
    @content;
  }
}

@mixin Large {
  @media (max-width: $large) {
    @content;
  }
}

@mixin UltraHD4K {
  @media (max-width: $ultraHD4K) {
    @content;
  }
}
