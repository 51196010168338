@import "App.scss";

.btnsBlock {
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  top: 20px;
  left: 20px;
  visibility: hidden;
  z-index: $z-index-1;
}

.container {
  position: relative;
  &:hover {
    background: #0000001f;
    outline: 1px dashed #005572;
    .btnsBlock {
      visibility: visible;
    }
  }
}
